<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isNotWeb$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isNotWeb$ | async) ? 'over' : 'side'"
      [opened]="false">
    <mat-toolbar>
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        fxFlex fxLayoutAlign="flex-end center">
        Meny
      </button>
    </mat-toolbar>
    <mat-nav-list fxLayout="column">
      <div class="upper-menu" fxFlex.lt-lg="35">
        <p mat-list-item>Dansa med oss</p>

        <!-- <a mat-list-item [routerLink]="['/boka-klass/item', 1]" (click)="drawer.close()">Dancelink 2022 >></a> -->
        <a mat-list-item href="https://boka.dancelink.se/" target="_blank" (click)="drawer.close()">Klasser >></a>
        <a mat-list-item routerLink="/" routerLinkActive="__active" (click)="drawer.close()">Hem >></a>
      </div>
      <!-- <a mat-list-item routerLink="/boka-klass/varukorg">Varukorg</a>
      <a mat-list-item routerLink="/boka-klass/betala">Betala</a>
      <a mat-list-item routerLink="/gallery">Gallery</a> -->
      <hr>
      <p mat-list-item class="lower-menu __title">Om oss</p>
      <div class="lower-menu"
           fxLayout="column"
           fxLayout.gt-md="row"
           fxFlex.lt-lg="60">
        <div fxFlex="100" fxFlex.gt-md="50" class="upper">
          <a mat-list-item routerLink="/about/vad-ar-dancelink" (click)="drawer.close()">Vad är DanceLink? >></a>
          <a mat-list-item routerLink="/about/snacka-dans"(click)="drawer.close()">Snacka Dans >></a> <!-- detta ska vara en sub page till about -->
          <a mat-list-item routerLink="/about/team" (click)="drawer.close()">Team >></a> <!-- detta ska vara en sub page till about -->
          <a mat-list-item routerLink="/galleri" (click)="drawer.close()">Bildgalleri >></a>
        </div>
        <div fxFlex="100" fxFlex.gt-md="50">
          <a mat-list-item routerLink="/faq" (click)="drawer.close()">FAQ >></a>

          <div class="social">
            <a mat-list-item href="https://www.instagram.com/dancelinkpg/" target="_blank">Instagram >></a>
            <a mat-list-item href="https://www.facebook.com/Dewitaproductions" target="_blank">Facebook >></a>
          </div>
        </div>
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary"
    [ngClass]="currentUrl ? 'general' : 'landing'">
      <!-- <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isNotWeb$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button> -->

      <!-- Add breadcrumbs here -->

      <div class="nav-content" fxFlex fxLayout="space-between" fxLayoutAlign.gt-md="flex-end center" fxFlex="100">
        <!-- Put any links in toolbar here  -->

        <img src="../../assets/img/dancelink_logo_pink.png"
             id="nav-logo"
             alt="logo"
             fxFlex="33"
             fxFlexOffset="33"
             [ngClass]="(isNotWeb$ | async) ? 'mobile' : 'web'">

        <button
        fxFlex="33  "
        fxFlexAlign="center"
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        id="menu-button">
        Meny
      </button>
      </div>
    </mat-toolbar>
    <!-- Add Content Here -->

    <router-outlet></router-outlet>

    <app-footer>

    </app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
