<section>
  <img class="banner" src="../../../assets/img/snacka dans_2.JPG">
  <div class="snacka-dans-med-oss"
       fxLayout="column"
       fxLayout.gt-md="row">
    <div fxFlex.gt-sm="70" class="content">
      <h2>Snacka dans med oss!</h2>
      <p>
        DanceLink anser att dans handlar först och främst om kropp,
        och kroppen finns med oss hela livet. Kroppen är alltid uttryckande,
        den kan vara funktionell, kommunikativ och kreativ, men också politisk. Enligt oss är kroppen
        påverkad av den tid vi lever i och eftersom dans och kropp är knutna med varandra blir också
        dansen påverkad av samtiden.<br><br>
        Vi lär oss av kulturen vi lever i. Men vad sker då när dansstilar som härstammar från andra kulturer
        ska läras ut på DanceLink? Hur kan det ske på ett respektfullt och inkluderande sätt?
        Detta  är frågor och en diskussion vi gärna delar med våra deltagare i form av samtal och föreläsningar i
        kombination med våra dansklasser.
      </p>

      <div class="cta"
           *ngIf="isWeb$ | async">
        <h3>
          Har du  idéer om hur man kan snacka dans?
          <br>
          Hör av dig till oss!
        </h3>
        <a href="mailto:kontakt@dancelink.se"
           mat-flat-button color="pink" class="rounded">
          Kontakta oss >>
        </a>
      </div>
    </div>


    <div class="blob-container" fxFlex fxLayoutAlign="space-evenly stretch" >
      <!-- <img src="../../../assets/img/"> -->
      <ul fxLayout="column" fxLayoutAlign="space-evenly stretch" >
        <p>När vi väljer våra danslärare frågar vi oss: </p>
        <li>Vilken dansstil ska undervisas?</li>
        <li>Vem ska undervisa?  </li>
        <li>Vad och hur undervisas detta?</li>
        <li>Vilken kunskap är viktig att få med sig från klassen? </li>
        <li>Hur presenteras klassen för våra elever på hemsida och sociala medier? </li>
      </ul>
    </div>
  </div>

  <div class="verktyg">
    <h2 *ngIf="isWeb$ | async">Ett verktyg för reflektion, inkludering och utveckling</h2>
    <div class="sidebar-imgs"
         fxLayout="column"
         fxLayout.gt-md="row"
         fxLayoutAlign="space-between">
      <div class="img-column" fxFlex="45">
        <img src="../../../assets/img/snacka dans_2.JPG" *ngIf="isWeb$ | async">
        <img src="../../../assets/img/snacka dans_3.jpg" class="img-2">
      </div>
      <h2 *ngIf="isNotWeb$ | async">Ett verktyg för reflektion, inkludering och utveckling</h2>
      <div fxFlex>
        <div>
          <h3>Reflektion </h3>
          <p>Genom att reflektera om vad kroppen gör på dansgolvet och
            sätta ord på kroppens upplevelser kan eleven fördjupa sin förståelse för dansen.
            <br><br>
            Efter varje DanceLink klass/workshops gör vi som verksamhet en utväderdering för
            hur det gått och vad som kan förbättras. Även våra elever får utvärdera oss och det upplägg dem deltagit på.
          </p>
        </div>

        <div>
          <h3>Inkludering </h3>
          <p>
            Genom att snacka tillsammans får alla synas och höras, oavsett bakgrund eller erfarenhet.
            Vi lyssnar på varandra för att kunna mötas.
            Detta gäller i all kommunikation på DanceLink.
          </p>
        </div>

        <div>
          <h3>Utveckling </h3>
          <p>
            Med reflektion kommer utveckling, vi snackar för att t.ex. ge feedback till varandra,
            ta del av nya perspektiv och för att gemensamt komma fram till nya beslut.
            Med ny information kan vi öppna upp och ta ställning till hur vi ska jobba vidare, både som elev och som verksamhet.
          </p>
        </div>

        <div class="cta"
             *ngIf="isNotWeb$ | async">
        <h3>
          Har du  idéer om hur man kan snacka dans?
          <br>
          Hör av dig till oss!
        </h3>
        <a href="mailto:kontakt@dancelink.se"
           mat-flat-button color="pink" class="rounded">
          Kontakta oss >>
        </a>
      </div>
      </div>

    </div>
  </div>
</section>
