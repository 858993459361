<section fxLayoutalign="space-evenly">


  <div class="row">
    <div class="column">
      <img src="../../assets/img/imggallery/dancelink_2021_01.jpg" alt="group dancing together">
      <img src="../../assets/img/imggallery/dancelink_2021_02.jpg" alt="two people in a dance studio">
      <img src="../../assets/img/imggallery/dancelink_2021_03.jpg" alt="dance teacher teaching class">
      <img src="../../assets/img/imggallery/dancelink_2021_04.jpg" alt="single person dancing by window">
      <img src="../../assets/img/imggallery/dancelink_2021_05.jpg" alt="danceclass overview">
      <img src="../../assets/img/imggallery/dancelink_2021_06.jpg" alt="single person dancing in black and white">
      <img src="../../assets/img/imggallery/dancelink_2021_07.jpg" alt="male dancer looking down">
      <img src="../../assets/img/imggallery/dancelink_2021_08.jpg" alt="students watching teacher demo">
      <img src="../../assets/img/imggallery/dancelink_2021_09.jpg" alt="students dancing choreography">
      <img src="../../assets/img/imggallery/dancelink_2021_10.jpg" alt="group danceing together">
      <img src="../../assets/img/imggallery/dancelink_2021_11.jpg" alt="dancer in a dip">
      <img src="../../assets/img/imggallery/dancelink_2021_12.jpg" alt="dancers in a cypher">
      <img src="../../assets/img/imggallery/dancelink_2021_13.jpg" alt="dance teacher smiling in class">
      <img src="../../assets/img/imggallery/dancelink_2021_14.jpg" alt="group dancing together">
      <img src="../../assets/img/imggallery/dancelink_2021_15.jpg" alt="group dancing together with energy">
      <img src="../../assets/img/imggallery/dancelink_2021_16.jpg" alt="solo dancer">
      <img src="../../assets/img/imggallery/dancelink_2021_17.jpg" alt="student in class">
      <img src="../../assets/img/imggallery/dancelink_2021_18.jpg" alt="dance teacher smiling">
      <img src="../../assets/img/imggallery/dancelink_2021_19.jpg" alt="watching dance class">
      <img src="../../assets/img/imggallery/dancelink_2021_20.jpg" alt="dance class viewed from above">
      <img src="../../assets/img/imggallery/dancelink_2021_21.jpg" alt="single dancer dancing by big window">
      <img src="../../assets/img/imggallery/dancelink_2021_22.jpg" alt="students and teacher talking in a circle">
      <img src="../../assets/img/imggallery/dancelink_2021_23.jpg" alt="students in dance class">
      <img src="../../assets/img/imggallery/dancelink_2021_24.jpg" alt="dancer in sun window">
      <img src="../../assets/img/imggallery/dancelink_2021_25.jpg" alt="two dancers together">
      <img src="../../assets/img/imggallery/dancelink_2021_106.jpg" alt="students in dance class">
    </div>
    <div class="column">
      <img src="../../assets/img/imggallery/dancelink_2021_26.jpg" alt="students in dance class viewed from above">
      <img src="../../assets/img/imggallery/dancelink_2021_27.jpg" alt="dance teacher in dance class">
      <img src="../../assets/img/imggallery/dancelink_2021_28.jpg" alt="two students in class">
      <img src="../../assets/img/imggallery/dancelink_2021_29.jpg" alt="three students in class">
      <img src="../../assets/img/imggallery/dancelink_2021_30.jpg" alt="two students in class">
      <img src="../../assets/img/imggallery/dancelink_2021_31.jpg" alt="a group of dancers lying on the floor">
      <img src="../../assets/img/imggallery/dancelink_2021_32.jpg" alt="students in class">
      <img src="../../assets/img/imggallery/dancelink_2021_33.jpg" alt="solo dancer on the floor">
      <img src="../../assets/img/imggallery/dancelink_2021_34.jpg" alt="duo dancing for other students">
      <img src="../../assets/img/imggallery/dancelink_2021_35.jpg" alt="dance teacher and students dancing">
      <img src="../../assets/img/imggallery/dancelink_2021_36.jpg" alt="student focusing">
      <img src="../../assets/img/imggallery/dancelink_2021_37.jpg" alt="teacher and students in a circle laughing">
      <img src="../../assets/img/imggallery/dancelink_2021_38.jpg" alt="students listening">
      <img src="../../assets/img/imggallery/dancelink_2021_39.jpg" alt="solo dancer in a window">
      <img src="../../assets/img/imggallery/dancelink_2021_40.jpg" alt="students talking">
      <img src="../../assets/img/imggallery/dancelink_2021_41.jpg" alt="dancer on the floor">
      <img src="../../assets/img/imggallery/dancelink_2021_42.jpg" alt="students in dance class">
      <img src="../../assets/img/imggallery/dancelink_2021_43.jpg" alt="students in dance class">
      <img src="../../assets/img/imggallery/dancelink_2021_44.jpg" alt="students in dance class">
      <img src="../../assets/img/imggallery/dancelink_2021_45.jpg" alt="students in dance class">
      <img src="../../assets/img/imggallery/dancelink_2021_46.jpg" alt="students in dance class">
      <img src="../../assets/img/imggallery/dancelink_2021_47.jpg" alt="dance teacher and students dancing">
      <img src="../../assets/img/imggallery/dancelink_2021_48.jpg" alt="dance teacher and students dancing">
      <img src="../../assets/img/imggallery/dancelink_2021_49.jpg" alt="two students in class">
      <img src="../../assets/img/imggallery/dancelink_2021_50.jpg" alt="solo dancer by window">
      <img src="../../assets/img/imggallery/dancelink_2021_105.jpg" alt="solo dancer by window">
    </div>
    <div class="column">

      <img src="../../assets/img/imggallery/dancelink_2021_52.jpg" alt="solo dancer in class">
      <img src="../../assets/img/imggallery/dancelink_2021_53.jpg" alt="students in dance class">
      <img src="../../assets/img/imggallery/dancelink_2021_54.jpg" alt="teacher teaching class">
      <img src="../../assets/img/imggallery/dancelink_2021_55.jpg" alt="solo dancer in class">
      <img src="../../assets/img/imggallery/dancelink_2021_56.jpg" alt="students in dance class jumping">
      <img src="../../assets/img/imggallery/dancelink_2021_57.jpg" alt="students in dance class viewed from the back">
      <img src="../../assets/img/imggallery/dancelink_2021_58.jpg" alt="focus on one student dancing in class">
      <img src="../../assets/img/imggallery/dancelink_2021_59.jpg" alt="students in class">
      <img src="../../assets/img/imggallery/dancelink_2021_60.jpg" alt="students in class">
      <img src="../../assets/img/imggallery/dancelink_2021_61.jpg" alt="one student in dance class">
      <img src="../../assets/img/imggallery/dancelink_2021_62.jpg" alt="student in class looking happy">
      <img src="../../assets/img/imggallery/dancelink_2021_63.jpg" alt="students in class">
      <img src="../../assets/img/imggallery/dancelink_2021_64.jpg" alt="trio in dance class">
      <img src="../../assets/img/imggallery/dancelink_2021_65.jpg" alt="student posing in dance class">
      <img src="../../assets/img/imggallery/dancelink_2021_66.jpg" alt="students in dance class">
      <img src="../../assets/img/imggallery/dancelink_2021_67.jpg" alt="one student in dance class upclose">
      <img src="../../assets/img/imggallery/dancelink_2021_68.jpg" alt="a group of people dancing with energy">
      <img src="../../assets/img/imggallery/dancelink_2021_69.jpg" alt="a group of people dancing with energy">
      <img src="../../assets/img/imggallery/dancelink_2021_70.jpg" alt="a group of people dancing with energy">
      <img src="../../assets/img/imggallery/dancelink_2021_71.jpg" alt="students in class working on angles">
      <img src="../../assets/img/imggallery/dancelink_2021_72.jpg" alt="students in voguing class">
      <img src="../../assets/img/imggallery/dancelink_2021_73.jpg" alt="students in voguing class ">
      <img src="../../assets/img/imggallery/dancelink_2021_74.jpg" alt="male student in voguing class">
      <img src="../../assets/img/imggallery/dancelink_2021_75.jpg" alt="male student in voguing class">
      <img src="../../assets/img/imggallery/dancelink_2021_103.jpg" alt="students in jazz class">
      <img src="../../assets/img/imggallery/dancelink_2021_104.jpg" alt="students dancing on the floor">
    </div>
    <div class="column">
      <img src="../../assets/img/imggallery/dancelink_2021_76.jpg" alt="students and teacher sitting in a circle">
      <img src="../../assets/img/imggallery/dancelink_2021_77.jpg" alt="one student in class dancing">
      <img src="../../assets/img/imggallery/dancelink_2021_78.jpg" alt="student and teacher dancing">
      <img src="../../assets/img/imggallery/dancelink_2021_79.jpg" alt="students dancing in a cypher">
      <img src="../../assets/img/imggallery/dancelink_2021_80.jpg" alt="students dancing popping">
      <img src="../../assets/img/imggallery/dancelink_2021_81.jpg" alt="two male dancers dancing together">
      <img src="../../assets/img/imggallery/dancelink_2021_82.jpg" alt="student freestyling">
      <img src="../../assets/img/imggallery/dancelink_2021_83.jpg" alt="student looking happy in class">
      <img src="../../assets/img/imggallery/dancelink_2021_84.jpg" alt="teachers and students in cypher">
      <img src="../../assets/img/imggallery/dancelink_2021_85.jpg" alt="teachers and students in cypher">
      <img src="../../assets/img/imggallery/dancelink_2021_86.jpg" alt="teachers and students in cypher">
      <img src="../../assets/img/imggallery/dancelink_2021_87.jpg" alt="students in dance class">
      <img src="../../assets/img/imggallery/dancelink_2021_88.jpg" alt="students in dance class">
      <img src="../../assets/img/imggallery/dancelink_2021_89.jpg" alt="students dancing together in dance class">
      <img src="../../assets/img/imggallery/dancelink_2021_90.jpg" alt="students in dance class">
      <img src="../../assets/img/imggallery/dancelink_2021_91.jpg" alt="solo dancer creating a circle with hands">
      <img src="../../assets/img/imggallery/dancelink_2021_92.jpg" alt="students posing in dance class">
      <img src="../../assets/img/imggallery/dancelink_2021_93.jpg" alt="students focusing and looking tired">
      <img src="../../assets/img/imggallery/dancelink_2021_94.jpg" alt="solo dancer opening up body toward walls">
      <img src="../../assets/img/imggallery/dancelink_2021_95.jpg" alt="students lying on the floor">
      <img src="../../assets/img/imggallery/dancelink_2021_96.jpg" alt="student sitting in two 90 degrees positions">
      <img src="../../assets/img/imggallery/dancelink_2021_97.jpg" alt="teacher in class">
      <img src="../../assets/img/imggallery/dancelink_2021_98.jpg" alt="students in class">
      <img src="../../assets/img/imggallery/dancelink_2021_99.jpg" alt="students in class smiling">
      <img src="../../assets/img/imggallery/dancelink_2021_100.jpg" alt="students in class smiling">
      <img src="../../assets/img/imggallery/dancelink_2021_101.jpg" alt="students in class smiling">
      <img src="../../assets/img/imggallery/dancelink_2021_102.jpg"alt="students in class">
    </div>
  </div>

</section>
