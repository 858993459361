<section>

  <h2>Boknings/köpsvillkor</h2>

  <div class="bokning">

    <h3> 1. Bokning </h3>
    <p>
      Bokning sker via bokningsformuläret på DanceLink.se. DanceLink skickar därefter en bekräftelse
      som bekräftar att du fått plats på önskad klass/klasspaket.
      Om det är fullbokat informeras du om att du hamnat på en väntelista. <br>
      Vi kontaktar dig om det skulle bli en plats ledig.
    </p>
</div>


<div class="Betalning">
    <h3> 2. Betalning</h3>


      <h4> 2.1. Faktura</h4>
      <p>
        Bokningar som görs via dancelink.se kan betalas via faktura med betalningsvillkor på 30 dagar.
        Om dansklassen är inom 30 dagar blir betalningsdatumet på fakturan samma som startdatumet på klassen.
        Om du inte betalat efter sista betalningsdatum tillkommer en påminnelseavgift på 50kr. Efter betalning är bokningen bindande.
        Klassen ska vara betald innan du går in på klass.
      </p>


    <h4> 2.2 Kortbetalning </h4>
    <p>
      Bokningar som görs via dancelink.se kan betalas med kortbetalning. Betalning sker direkt i samband med bokning.
      Efter betalning är bokningen bindande.
      Klassen ska vara betald innan du går in på klass.
    </p>

    <h4> 2.3 Swish </h4>
    <p>
      Swish används som betalningslösning vid bokningar som görs på plats (drop in).
      Efter betalning är bokningen bindande. Klassen ska vara betald innan du går in på klass.
    </p>
  </div>

  <div class="avbokning">

    <h3>3. Avbokning & återbetalning</h3>

      <h4>3.1 Sjukdom</h4>
      <p>
      För återbetalning vid sjukdom ska läkarintyg uppvisas vid avbokning. Återbetalning av fullt belopp sker inom en vecka.
      Vid sjukdom ska DanceLink kontaktas via kontakt@dancelink.se.
    </p>

    <h4>3.2 Återbetalning utan läkarintyg</h4>
    <p>
    Vi återbetalar inte avbokningar utan läkarintyg.

    <h4>3.3 Avbokningar med obetald faktura</h4>
    <p>Om du valt att betala via faktura är bokningen binande när fakturan är betald.
     Fakturor makuleras vid avbokningar som kommer in innan fakturan är betald.
    Avbokningar tas emot via kontakt@dancelink.se.
  </p>


  </div>

</section>
