<section fxLayout="column">
  <div class="team">

    <h2>Team</h2>
    <div class="cards" fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="center">


      <mat-card class="example-card" fxFlex="100" fxFlex.gt-md="33" >
        <mat-card-header>
          <div mat-card-avatar class="example-header-image"></div>

        </mat-card-header>
        <img mat-card-image class="team-image" src="../../../assets/img/team_kicka.jpg" alt="Teambild kicka">
        <mat-card-content>
          <mat-card-title>Fredrika Hydén </mat-card-title>
          <mat-card-subtitle>Fotograf</mat-card-subtitle>
          <p>

          </p>
        </mat-card-content>
      </mat-card>

      <mat-card class="example-card" fxFlex="100" fxFlex.gt-md="33">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>

      </mat-card-header>
      <img mat-card-image class="team-image" src="../../../assets/img/team_emma.JPG" alt="Teambild emma">
      <mat-card-content>
        <mat-card-title>Emma Öberg </mat-card-title>
        <mat-card-subtitle>Projektansvarig</mat-card-subtitle>
        <mat-card-subtitle>Dewita Productions</mat-card-subtitle>
        <p>
          <a href="mailto:emma@dancelink.se">emma@dancelink.se</a>
          <br>
          <a href="mailto:kontakt@dancelink.se">kontakt@dancelink.se</a>
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="example-card" fxFlex="100" fxFlex.gt-md="33">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>

      </mat-card-header>
      <img mat-card-image class="team-image" src="../../../assets/img/team_alvin.jpg" alt="Teambild alvin">
      <mat-card-content>
        <mat-card-title>Alvin Zedendahl</mat-card-title>
        <mat-card-subtitle>Admin & webutveckling</mat-card-subtitle>

        <p>
          <a href="mailto:kontakt@dancelink.se">kontakt@dancelink.se</a>
        </p>
      </mat-card-content>
    </mat-card>
  </div>
  </div>

  <div class="call-to-action">
    <p>
      Vill du undervisa på DanceLink? Skicka mejl till <a href="mailto:emma@dancelink.se">emma@dancelink.se</a> med cv och info om dig!
    </p>
  </div>

  <div class="previous-years">
<h2>Tidigare undervisare på DanceLink</h2>
<article fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="stretch">
  <div fxFlex="100" fxFlex.gt-md="33">
    <h3>2022</h3>
    <p>Workshops</p>
    <ul>
      <li>Louie Looselegs Indriana</li>
      <li>Emma Öberg</li>
    </ul>
  </div>
  <div fxFlex="100" fxFlex.gt-md="33">
    <h3>2021</h3>
    <ul>
      <li>Benjamin Trossö</li>
      <li>Elsa Lundberg</li>
      <li>Mario Perez Amigo</li>
      <li>Angela Arcueno</li>
      <li>Celia Caap</li>
      <li>Emma Öberg</li>
      <li>Ebba Hatcher</li>
      <li>Lisa Markus</li>
      <li>Justin Ice-o</li>
      <li>Jonas Luis</li>
      <li>Nora Mccay</li>
      <li>Felicia Rivera Cetre</li>
      <li>Marvil Iglesias</li>
    </ul>
  </div>
  <div fxFlex="100" fxFlex.gt-md="33">
    <h3>2020</h3>
    <ul>
      <li>Benjamin Trossö</li>
      <li>Elsa Lundberg</li>
      <li>Mario Perez Amigo</li>
      <li>Celia Caap</li>
      <li>Emma Öberg</li>
      <li>Nora Mccay</li>
      <li>Fanny Svensson</li>
      <li>Nora Mccay</li>
      <li>Vilma Simonsson</li>
      <li>Hanna Levander Frylertz</li>
      <li>Alvin Zedendahl</li>
    </ul>
  </div>
</article>



  </div>
</section>
