<section fxLayout="column" >



  <img src="../../../assets/img/om_oss_01.jpg" class="banner">

  <div class="what"
       fxLayout="column"
       fxLayout.gt-md="row"
       fxFlex="100"
       fxFlex.gt-md="50"
       fxLayoutAlign="space-between">

    <div class="about"
         fxFlex="100"
         fxFlex.gt-md="55"
         fxFlexOrder="1"
         fxFlexOrder.lt-lg="2">
      <h2>Vad är DanceLink?</h2>
      <p>DanceLink är en pedagogisk verksamhet som fick sin start år 2020 i regi av Dewita Productions.
      Idag är DanceLink ett event under sommaren där vi erbjuder dansklasser i flera olika dansstilar.
      DanceLink är känt för att bjuda in yrkesverksamma dansare och koreografer som håller klasser där vi tillsammans jobbar med teknik,
      musikalitet och kreativitet.
      <br>
      <br>
      DanceLink vill skapa en trygg miljö för dig som önskar att ta din dans till nästa nivå,
      våga utmana dig själv och testa på något nytt. Hobbydansare, blivande professionella och yrkesverksamma är välkomna till oss!</p>

      <button mat-flat-button color="pink" class="rounded" fxFlexAlign="center">
        <a href="https://boka.dancelink.se" target="_blank"> Se alla klasser >></a>
      </button>
    </div>

    <div class="blob-container"
         fxLayoutAlign="end center"
         fxFlex="100"
         fxFlex.gt-md="45"
         fxFlexOrder="2"
         fxFlexOrder.lt-lg="1">
     <!-- <img src="../../../assets/img/vadardancelink_blob1 (2).png"> -->
      <ul>
        <li>Träffa likasinnade.</li>
        <li>Träna dansteknik, musikalitet & koreografi.</li>
        <li>Utforska ditt eget uttryck & skapa själv.</li>
        <li> Snacka dans genom dialog, samtal och feedback.</li>
        <li>Ta klasser för professionella inom dansfältet.</li>
      </ul>
    </div>
  </div>

  <article class="icons" fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="space-evenly center" fxLayoutAlign.gt-md="center center">
    <div class="row" fxLayout="row" fxLayoutAlign.lt-md="space-around">
      <div class="icon-container" fxFlex="48" fxFlex.gt-md="50">
        <img src="../../../assets/icons/referrals_icon_1.svg" alt="">
        <h3 class="font-r">Kreatvitet</h3>
        <p>
          Skapa rörelse och utforska ditt eget uttryck.
        </p>
      </div>
      <div class="icon-container" fxFlex="48" fxFlex.gt-md="50">
        <img src="../../../assets/icons/referrals_icon_2.svg" alt="">
        <h3 class="font-r">Utveckling</h3>
        <p>
          Se utveckling i dansteknik, musikalitet och komposition.
        </p>
      </div>
    </div>
    <div class="row" fxLayout="row" fxLayoutAlign="space-around">
      <div class="icon-container" fxFlex="48" fxFlex.gt-md="50">
        <img src="../../../assets/icons/referrals_icon_3.svg" alt="">
        <h3 class="font-r">Möten</h3>
        <p>
          Träffa likasinnade vänner och dansa för professionella dansare och koreografer.
        </p>
      </div>
      <div class="icon-container" fxFlex="48" fxFlex.gt-md="50">
        <img src="../../../assets/icons/referrals_icon_4.svg" alt="">
        <h3 class="font-r">Kommunikation</h3>
        <p>
          Ge och få feedback. Reflektera och snacka dans med oss.
        </p>
      </div>
    </div>
  </article>




  <div class="vision">

    <img src="../../../assets/img/om_oss_02.jpg" class="banner">

    <div fxLayout="row" fxLayoutAlign="space-between stretch" class="row">
      <!-- <div fxFlex="35" class="side">
        <img src="../../../assets/img/team_kicka.jpg">
      </div> -->
      <div class="content">
        <h2>Vision & mål</h2>
        <p>

          DanceLinks vision är att skapa en platform som främjar den växande danskulturen i Östergötland.
          Vi vill skapa en miljö där utbyte av kunskap blir lättillgängligt oavsett om man är danselev eller en del av
          det professionella dansfältet. Målet är att skapa möten mellan danselev och danselev, danselev och professionell,
          men också mellan professionell och professionell, där alla får möjlighet att utväxla och utveckla.
          <br><br>
          Vi vill gynna ett tvärfackligt samarbete i det professionella dansfältet genom att finnas som mötesplats i Linköping.
          DanceLink bjuder därför  in internationellt och/eller nationellt aktiva yrkesverksamma dansare med olika bakgrund att dela
          sin kunskap med varandra och våra elever.  För DanceLink är kommunikation ett ord som genomsyrar sättet vi presenterar vår
          verksamhet på och vi anser att det är viktigt att kunna snacka dans med varandra.
        </p>
        <button mat-flat-button color="pink" class="rounded">
          <a mat-list-item routerLink="/about/snacka-dans"> Läs mer om hur vi snackar dans >></a>
        </button>
      </div>
    </div>
  </div>


</section>
