<section>
  <div class="hero-logo">
    <img src="../../../assets/img/dancelink_logo_pink.png" alt="">
  </div>

  <div class="text">
    <p>
      DanceLink är en pedagogisk verksamhet med fokus på dans och rörelse.
      Vi möter våra elevers behov av utveckling genom att erbjuda klasser i en bredd av olika dansstilar som hålls av yrkesverksamma i dansfältet.
    </p>
    <p>
      Tillsammans jobbar vi med teknik, musikalitet & kreativitet i en trygg miljö.
      DanceLink finns för dig som vill ta din dans till nästa nivå!
    </p>
  </div>

  <button mat-flat-button color="pink" class="rounded">
    <a mat-list-item routerLink="/about/vad-ar-dancelink"> Läs mer >></a>
  </button>
</section>
