<section fxFlex
    fxLayout="column"
    fxLayoutAlign="space-evenly center" >
  <h2 class="title">
    Snacka dans med oss!
  </h2>

  <p>
    Verktyget för reflektion, inkludering och utveckling.
  </p>

  <button mat-flat-button color="pink" class="rounded">
    <a mat-list-item routerLink="/about/snacka-dans"> Läs mer >></a>
  </button>
</section>

