import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-focus',
  templateUrl: './product-focus.component.html',
  styleUrls: ['./product-focus.component.scss']
})
export class ProductFocusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
