<section fxLayout="column" fxLayoutAlign.gt-md="space-between">

  <div
  class="referral"
  fxLayout="column"
  fxLayoutAlign="space-evenly center"
  fxFlex.gt-md="70">
    <video autoplay muted loop id="referral-background" [muted]="true">
      <source src="../../../assets/img/DanceLink_landing_draft.mp4" type="video/mp4">
    </video>
    <h2 class="font-r" #referral>"{{ this.referrals[0] }}"</h2>

    <button mat-flat-button color="pink" class="rounded big">
      <a href="https://boka.dancelink.se" target="_blank"> Se alla klasser >></a>
    </button>
  </div>

  <article class="icons" fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="space-evenly center" fxLayoutAlign.gt-md="center center">
    <div class="row" fxLayout="row" fxLayoutAlign.lt-md="space-around">
      <div class="icon-container" fxFlex="48" fxFlex.gt-md="50">
        <img src="../../../assets/icons/referrals_icon_1.svg" alt="">
        <h3 class="font-r">Kreatvitet</h3>
        <p>
          Skapa rörelse och utforska ditt eget uttryck.
        </p>
      </div>
      <div class="icon-container" fxFlex="48" fxFlex.gt-md="50">
        <img src="../../../assets/icons/referrals_icon_2.svg" alt="">
        <h3 class="font-r">Utveckling</h3>
        <p>
          Se utveckling i dansteknik, musikalitet och komposition.
        </p>
      </div>
    </div>
    <div class="row" fxLayout="row" fxLayoutAlign="space-around">
      <div class="icon-container" fxFlex="48" fxFlex.gt-md="50">
        <img src="../../../assets/icons/referrals_icon_3.svg" alt="">
        <h3 class="font-r">Möten</h3>
        <p>
          Träffa likasinnade vänner och dansa för professionella dansare och koreografer.
        </p>
      </div>
      <div class="icon-container" fxFlex="48" fxFlex.gt-md="50">
        <img src="../../../assets/icons/referrals_icon_4.svg" alt="">
        <h3 class="font-r">Kommunikation</h3>
        <p>
          Ge och få feedback. Reflektera och snacka dans med oss.
        </p>
      </div>
    </div>
  </article>
</section>

<!-- insert background  -->
