import { Component, AfterViewInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.scss']
})
export class ReferralsComponent implements AfterViewInit {
  @ViewChild('referral') referral: ElementRef;
  public referrals = ['Blev så inspirerad och taggad på att dansa mer och utvecklas.', 'Är så tacksam att ni tar denna möjligheten till Linköping!', 'Det var väldigt bra spridning på stilarna. Det finns något för alla!', 'Att utvecklas, utmanas och bara få leva - alla förväntningar uppnåddes och överträffades!', 'Riktigt härligt och bra arrangerat, ser fram emot nästa år!']
  public currentReferral = 1;

  constructor() { }

  ngAfterViewInit(): void {
    setInterval(()=> { //This function changes the text of the referral every 4s
      this.spinReferral();
    }, 4000);
  }

  spinReferral() {
    this.referral.nativeElement.innerHTML = `"${this.referrals[this.currentReferral]}"`;
    if(this.currentReferral == this.referrals.length - 1) {
      this.currentReferral = 0;
    } else {
      this.currentReferral += 1;
    }

  }

}
