<section div fxLayout="row" fxLayoutAlign="space-between end" [ngClass]="currentUrl ? 'general' : 'landing'">
  <article>


    <p>
      Postadress:
    </p>
    <p>
      Drabantgatan 43
    </p>
    <p>
      582 14 Linköping
    </p>
    <br>
    <a href="https://instagram.com/dancelinkpg">Instagram </a> <br>

    <a href="https://facebook.com/dewitaproductions">Facebook</a>
    <a href="mailto:kontakt@dancelink.se?subject = Kontakt"><br>
      kontakt@dancelink.se
    </a>
    <p>
      Webbsida av Alvin Zedendahl
    </p>
  </article>

  <article fxLayout="row" fxLayoutAlign="space-evenly center">
    <p *ngIf="isWeb$ | async"> Med stöd av:</p>
    <img src="../../assets/icons/activebudofbbig.jpg" alt="ABICARE logo" class="abicare">
    <img src="../../assets/icons/dewitaproductions.png" alt="Dewita Productions logo" class="dewita-productions">
  </article>
</section>
