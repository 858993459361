<section fxFlex
         fxLayout.lt-lg="column"
         fxLayout.gt-md="row"
         fxLayoutAlign="space-evenly stretch"
         id="newsletter"
         ngClass.lt-md="mobile">
  <div class="info"
       fxFlex="35"
       fxFlex.gt-md="50"
       fxLayout="column"
       fxLayoutAlign.lt-lg="space-around start">
    <h3>Nyhetsbrev</h3>
    <p>
      Prenumerera på vårt nyhetsbrev för att hålla dig uppdaterad om nyheter och få tillgång till exklusiva rabatter och erbjudanden!
    </p>

    <div class="mailchimp-form">
            <!-- Begin Mailchimp Signup Form -->
      <link href="//cdn-images.mailchimp.com/embedcode/naked-10_7_dtp.css" rel="stylesheet" type="text/css">
      <style type="text/css">
        #mc_embed_signup{background:#fff; clear:left; padding: 0;}
        /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
          We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
      </style>
      <div id="mc_embed_signup">
        <form action="https://dancelink.us1.list-manage.com/subscribe/post?u=c2aa1859394c3ccf59a6a8eed&amp;id=c5da40c4bd" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
            <div id="mc_embed_signup_scroll">

        <div class="mc-field-group">
          <mat-form-field appearance="fill" style="width:80vmin; max-width: 100%;">
            <mat-label for="mce-EMAIL">Email Address </mat-label>
            <input matInput type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
          </mat-form-field>
        </div>
          <div id="mce-responses" class="clear foot">
            <div class="response" id="mce-error-response" style="display:none"></div>
            <div class="response" id="mce-success-response" style="display:none"></div>
          </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_c2aa1859394c3ccf59a6a8eed_c5da40c4bd" tabindex="-1" value=""></div>
              <div class="optionalParent">
                <div class="clear foot">
                  <button *ngIf="isNotWeb$ | async" mat-flat-button color="moss" type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="rounded" style="width:80vmin; max-width: 100%;">Sign up</button>
                  <button *ngIf="isWeb$ | async" mat-flat-button color="pink" type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="rounded" style="width:80vmin; max-width: 100%;">Sign up</button>
                </div>
              </div>
            </div>
        </form>
      </div>

      <!--End mc_embed_signup-->
    </div>
  </div>
  <div class="contact"
       fxFlex.gt-md="50"
       fxLayout="column">
    <h3>Kontakta oss</h3>

    <form class="example-form"
          fxFlex="1"
          fxLayout="column"
          fxLayoutAlign="stretch">
      <p>Fyll i formuläret</p>
      <mat-form-field appearance="fill">
        <mat-label>Ämne</mat-label>
        <input matInput>
      </mat-form-field>
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Email</mat-label>
        <input type="email" matInput [formControl]="emailFormControl" placeholder="Ex. pat@example.com">
        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Meddelande</mat-label>
        <textarea matInput></textarea>
      </mat-form-field>
      <button mat-flat-button color="pink" class="rounded">
        Skicka
      </button>
    </form>
  </div>
</section>


