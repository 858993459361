import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { VadArDancelinkComponent } from './about/vad-ar-dancelink/vad-ar-dancelink.component';
import { SnackaDansComponent } from './about/snacka-dans/snacka-dans.component';
import { TeamComponent } from './about/team/team.component';
import { BookingComponent } from './booking/booking.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CartComponent } from './cart/cart.component';
import { FaqComponent } from './faq/faq.component';
import { GalleryComponent } from './gallery/gallery.component';
import { LandingComponent } from './landing/landing.component';
import { PaymentComponent } from './payment/payment.component';
import { ProductFocusComponent } from './product-focus/product-focus.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';

const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'about', component: AboutComponent },
  { path: 'about/vad-ar-dancelink', component: VadArDancelinkComponent },
  { path: 'about/snacka-dans', component: SnackaDansComponent },
  { path: 'about/team', component: TeamComponent },
  // { path: 'boka-klass', component: BookingComponent }, // alla klasser
  // { path: 'boka-klass/dancelink-2022', component: },
  // { path: 'boka-klass/workshops', component: },
  // { path: 'boka-klass/merch', component: },
  // { path: 'boka-klass/varukorg', component: CartComponent },
  // { path: 'boka-klass/betala', component: PaymentComponent },
  // { path: 'boka-klass/item/:id', component: ProductFocusComponent },
  { path: 'faq', component: FaqComponent },
  // { path: 'bokningsvillkor', component: TermsAndConditionsComponent },
  // { path: 'kalender', component: CalendarComponent },
  { path: 'galleri', component: GalleryComponent },
  { path: '**', pathMatch: 'full', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
