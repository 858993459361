import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';
import { AboutComponent } from './about/about.component';
import { BookingComponent } from './booking/booking.component';
import { ProductFocusComponent } from './product-focus/product-focus.component';
import { CartComponent } from './cart/cart.component';
import { PaymentComponent } from './payment/payment.component';
import { FaqComponent } from './faq/faq.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { CalendarComponent } from './calendar/calendar.component';
import { GalleryComponent } from './gallery/gallery.component';
import { NavigationComponent } from './navigation/navigation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HeroComponent } from './landing/hero/hero.component';
import { ReferralsComponent } from './landing/referrals/referrals.component';
import { SnackaDansLandingComponent } from './landing/snacka-dans-landing/snacka-dans-landing.component';
import { SnackaDansComponent } from './about/snacka-dans/snacka-dans.component';
import { NewsletterComponent } from './landing/newsletter/newsletter.component';
import { FooterComponent } from './footer/footer.component';
import {MatInputModule} from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { VadArDancelinkComponent } from './about/vad-ar-dancelink/vad-ar-dancelink.component';
import { TeamComponent } from './about/team/team.component';
import {MatTreeModule} from '@angular/material/tree';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    AboutComponent,
    BookingComponent,
    ProductFocusComponent,
    CartComponent,
    PaymentComponent,
    FaqComponent,
    TermsAndConditionsComponent,
    CalendarComponent,
    GalleryComponent,
    NavigationComponent,
    HeroComponent,
    ReferralsComponent,
    SnackaDansLandingComponent,
    SnackaDansComponent,
    NewsletterComponent,
    FooterComponent,
    VadArDancelinkComponent,
    TeamComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatInputModule,
    MatCardModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    MatTreeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
