import { Component, OnInit } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

interface QNode {
  name: string;
  children?: QNode[];
}

const Q_DATA: QNode[] = [
  {
    name: '1. Vilken nivå ska jag välja?',
    children: [
      {name: 'Nivån på klassen framgår i klassbeskrviningen. <br>\
      Våra kurser för barn delas in efter ålder. Kurser och klasser för ungdomar och vuxna delas in efter nivå. <br> \
      <hr>\
      Nybörjare = Ingen tidigare förkunskap krävs. <br>\
      Medel = Grundläggande förkunskap och tidigare danseerfarenhet krävs. <br> \
      Avancerad =  Mycket förkunskap och flera års tidigare danserfarenhet krävs. <br>\
      Öppen nivå = Klassens nivå anpassas efter deltagarna på plats <br> \
      <hr>\
      Om du är osäker på om du kan delta, mejla <a href="mailto:kontakt@dancelink.se">kontakt@dancelink.se</a> där du beskriver din danserfarenhet, så hjälper vi dig! '}
    ]
  },
  {
    name: '2. Finns det en åldersgräns på sommareventet 2022?',
    children: [
      {name: 'Vi rekommenderar sommareventet DanceLink 2022 för dig som är över 12 år. <br>\
      Om du upplever att du har nog med danserfarenhet och är under vår rekommendationsålder \
      får du och dina föräldrar gärna skicka ett mejl till oss där ni beskriver tidigare danserfarenhet.<br> \
      Majoriteten av våra deltagare är mellan 16-20 år. DanceLink har ingen övre åldersgräns.  \
      '}
    ]
  },
  {
    name: '3. Kan jag avboka min klass?',
    children: [
      {name: 'Du kan alltid avboka dina klasser, men observera att bokningen är bindande när du betalat. Kontakta oss via <a href="mailto:kontakt@dancelink.se">kontakt@dancelink.se</a>!   <br> \
       Läs mer om våra bokningsvillkor <a href="https://dancelink.se/bokningsvillkor">här!</a> '}
    ]
  },
  {
    name: '4.	Varför genrespecificerar ni inte era koreografi-klasser?',
    children: [
      {name: '  \ \
      Många dansare och koreografer jobbar stilöverskridande och använder element från flera tekniker och dansstilar i sina koreografier.<br>\
      Detta gör det svårt att genrespecificera och vi väljer därför att kalla dessa klasser för koreografiklasser.<br> \
      <hr>\
      På våra genrespecificerade klasser kan det finnas koreografiska element, men det är inte ett måste. <br>\
       För oss är det viktigt att poängtera att det finns flera aspekter utöver koreografi som är viktiga när man ska lära sig en dansstil. <br>\
      <hr>\
       Våra koreografi-klasser lägger därför stort fokus på att lära sig plocka upp koreografi - och på lärarens egna uttryck. <br>\
       Om man är nyfiken på ungefär vilken stil man kan förvänta sig, rekommenderar vi att kolla upp klassbeskrivningen och/eller lärarens instagram/sociala medier för att få en översikt. \
       Det kan förekomma koreografi-klasser som är genrespecificerade, men då framgår det i klassbeskrivningen'}
    ]
  },
]

interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  private _transformer = (node: QNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level
    }
  }

  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level, node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor() {
    this.dataSource.data = Q_DATA;
   }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  ngOnInit(): void {
  }

}
