<section>
  <app-hero *ngIf="isWeb$ | async"></app-hero>

  <app-referrals></app-referrals>

  <app-snacka-dans-landing></app-snacka-dans-landing>

  <app-newsletter></app-newsletter>

</section>
